import React, { Component } from "react";
import { connect } from "react-redux";
import AdminNavBar from "../../components/UI/AdminNavBar";
import Footer from "../../components/UI/Footer";
import { PDFViewer } from "@react-pdf/renderer";
import RunsheetPdfCreator from "../../components/PdfComponent/RunSheetPdfCreator";
import ReactPDF from "@react-pdf/renderer";
import { PDFDownloadLink, Document, Page } from "@react-pdf/renderer";
import VendorNavBar from "../VendorApplyEventScreen/Component/VendorNavBar";
import * as Sentry from "@sentry/react";
import {
  store,
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import {
  Form,
  Container,
  Button,
  Row,
  Col,
  Accordion,
  Card,
  Modal,
  InputGroup,
  FormControl,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import DatePicker from "react-datepicker";
import Pagination from "react-bootstrap/Pagination";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { AWS_URL } from "../../assets/Constants";
import { TextField } from "material-ui";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
const today = new Date();
const sixMonthsFromToday = new Date();
sixMonthsFromToday.setMonth(today.getMonth() + 6);
class OperatorRunSheets extends Component {
  state = {
    isOperatorLoggedIn:
      getFromLocalStorage("isOperatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isOperatorLoggedIn") != "" &&
      getFromLocalStorage("isOperatorLoggedIn") != null
        ? getFromLocalStorage("isOperatorLoggedIn")
        : "false",
    isTruckManager:
      getFromLocalStorage("isTruckManager") != "undefined" &&
      getFromLocalStorage("isTruckManager") != "" &&
      getFromLocalStorage("isTruckManager") != null
        ? getFromLocalStorage("isTruckManager")
        : "false",
    isOperatorOperations:
      getFromLocalStorage("isOperatorOperations") != "undefined" &&
      getFromLocalStorage("isOperatorOperations") != "" &&
      getFromLocalStorage("isOperatorOperations") != null
        ? getFromLocalStorage("isOperatorOperations")
        : "false",
    email:
      getFromLocalStorage("email") != "undefined" &&
      getFromLocalStorage("email") != "" &&
      getFromLocalStorage("email") != null
        ? getFromLocalStorage("email")
        : "",
    password:
      getFromLocalStorage("password") != "undefined" &&
      getFromLocalStorage("password") != "" &&
      getFromLocalStorage("password") != null
        ? getFromLocalStorage("password")
        : "",
    bookingRequests: [],
    inviteVendorModal: true,
    vendors: [],
    nonCuisineVendors: [],

    startDate: new Date(),
    endDate: sixMonthsFromToday,
    completed_payments: 0,
    resetFilter:
      getFromLocalStorage("resetFilter") != "undefined" &&
      getFromLocalStorage("resetFilter") != "" &&
      getFromLocalStorage("resetFilter") != null
        ? getFromLocalStorage("resetFilter")
        : "false",
    pageSelected: 1,
    operatorEmail:
      getFromLocalStorage("operatorEmail") &&
      getFromLocalStorage("operatorEmail") != ""
        ? getFromLocalStorage("operatorEmail")
        : "",
    operatorPassword:
      getFromLocalStorage("operatorPassword") &&
      getFromLocalStorage("operatorPassword") != ""
        ? getFromLocalStorage("operatorPassword")
        : "",
    vendor_ids: [],
    selectedVendorId: "",
  };

  setStartDate = (date) => {
    this.setState({ startDate: date }, () => {
      saveToLocalStorage("startDate", this.state.startDate);
    });
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };

  setEndDate = (date) => {
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };
  componentWillMount() {
    const { startDate, endDate } = this.state;
    if (startDate.toString() == "Invalid Date") {
      this.setState({ startDate: new Date() });
    }
    if (endDate.toString() == "Invalid Date") {
      this.setState({ endDate: new Date() });
    }
  }

  componentDidMount() {
    const { isSuperAdminLoggedIn } = this.state;
    this.fetch_vendor_ids();
    this.getDashboardData(this.state.requestState);
  }

  login = () => {
    const { email, password } = this.state;
    this.setState({ isLoadingVendor: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "admin_login",
          email: email,
          password: password,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoadingVendor: false });
        if (dataResponse.success == true) {
          this.setState({ isSuperAdminLoggedIn: "true" }, () => {
            saveToLocalStorage("isSuperAdminLoggedIn", "true");
            saveToLocalStorage("email", email);
            saveToLocalStorage("password", password);
            this.getDashboardData(this.state.requestState);
          });
        } else {
          alert("Invalid email or password! Please try again!");
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoadingVendor: false });
      });
  };

  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    this.setState({ isSuperAdminLoggedIn: "false" });
    window.location.reload();
  };

  getVendors = (cuisine, booking_request_id) => {
    this.setState({ isLoadingVendor: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_cuisine_specific_vendors",
          cuisine: cuisine,
          booking_request_id: booking_request_id,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoadingVendor: false });
        if (dataResponse.success == true) {
          dataResponse.vendors.sort(function(a, b) {
            var nameA = a.title,
              nameB = b.title;
            if (nameA < nameB)
              //sort string ascending
              return -1;
            if (nameA > nameB) return 1;
            return 0; //default return value (no sorting)
          });
          this.setState({
            vendors: dataResponse.vendors,
            nonCuisineVendors: dataResponse.non_cuisine_vendors,
          });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoadingVendor: false });
      });
  };

  saveAdminNotes = (item, notes) => {
    const { email } = this.state;
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "update_admin_notes",
          booking_request_id: item.id,
          admin_notes: notes,
          email: email,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoadingVendor: false });
        if (dataResponse.success == true) {
          alert("Saved Successfully!");
        } else {
          alert(
            "Something went wrong while saving notes. Pelase try again later"
          );
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        alert(
          "Something went wrong while saving notes. Pelase try again later"
        );

        console.error(error);
        this.setState({ isLoadingVendor: false });
      });
  };

  sendRequestToVendor(booking_request_id, user_ptr_id) {
    this.setState({ isLoadingSendingVendorRequest: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "send_request_to_vendor",
          user_ptr_id: user_ptr_id,
          booking_request_id: booking_request_id,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoadingSendingVendorRequest: false });
        if (dataResponse.success == true) {
          alert("Sent Successfully!");
          var arr = this.state.requestSentIds;
          arr.push(user_ptr_id);
          this.setState({ requestSentIds: arr });
        } else {
          alert(
            "Something went wrong while sending request. Pelase try again later"
          );
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        alert(
          "Something went wrong while sending request. Pelase try again later"
        );
        console.error(error);
        this.setState({ isLoadingSendingVendorRequest: false });
      });
  }

  getDashboardData = (requestState) => {
    const {
      startDate,
      endDate,
      resetFilter,
      pageSelected,
      selectedVendorId,
    } = this.state;
    console.log(selectedVendorId);
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.operatorData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_run_sheets_for_stalls_operator",
          vendor_id: selectedVendorId,
          start_date: startDate,
          end_date: endDate,
          operator_id: JSON.parse(getFromLocalStorage("Operator"))[0].id,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoading: false });
        if (dataResponse.success == true) {
          this.setState({ bookingRequests: dataResponse.data });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  isEmpty = (obj) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  render() {
    const {
      isOperatorLoggedIn,
      stockUserLoggedIn,
      isTruckManager,
      isOperatorOperations,
    } = this.state;
    return (
      <div>
        {this.renderNavBar()}
        {isOperatorLoggedIn === "true" ||
        isTruckManager === "true" ||
        isOperatorOperations === "true"
          ? this.renderHome()
          : (window.location.href = "/operator-login")}
        <Container></Container>
      </div>
    );
  }

  fetch_vendor_ids = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_operator_details_for_stall_ids",
          operator_id: JSON.parse(getFromLocalStorage("Operator"))[0].id,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.BASE_URI + apiPaths.adminData, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success == true) {
          this.setState({ vendor_ids: result.stalls });
          this.setState({ selectedVendorId: result.stalls[0].user_ptr_id });
          console.log(result.stalls[0].user_ptr_id);
          this.getDashboardData(this.state.requestState);
        } else {
          alert(result.error);
        }
      });
  };

  renderNavBar() {
    const {
      stockUserLoggedIn,
      isOperatorLoggedIn,
      stockUserData,
      isTruckManager,
      isOperatorOperations,
    } = this.state;
    return (
      <VendorNavBar
        isOperatorLoggedIn={isOperatorLoggedIn}
        isOperatorOperations={isOperatorOperations}
        isTruckManager={isTruckManager}
      ></VendorNavBar>
    );
  }

  renderLogin() {
    const { email, password, isEventCoordinatorLoggedIn } = this.state;
    return (
      <div>
        <Container>
          <Form.Group>
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(text) => {
                this.setState({ email: text.target.value });
              }}
            />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={(text) => {
                this.setState({ password: text.target.value });
              }}
            />
          </Form.Group>

          <Button
            style={{
              width: "100%",
              marginBottom: 20,
              marginTop: 10,
              backgroundColor: "#ef3f6d",
            }}
            className="button-enabled-pink-style"
            onClick={() => {
              this.login();
            }}
            variant="primary"
            type="submit"
          >
            Login
          </Button>
        </Container>
      </div>
    );
  }

  renderHome() {
    const {
      bookingRequests,
      availableStatus,
      isLoadingChangeBookingStatus,
      adminNotesChanged,
      isLoading,
      requestState,
      isOperatorLoggedIn,
      startDate,
      endDate,
      completed_payments,
      pageSelected,
      vendor_ids,
      selectedVendorId,
      stallNameNotes,
    } = this.state;
    return (
      <div>
        {!isLoading ? (
          <>
            <Container
              style={{
                borderWidth: 1,
                borderColor: "grey",
                borderStyle: "solid",
                borderRadius: 5,
                padding: 15,
              }}
            >
              <Row>
                <Col xs={12} className="header">
                  <h2>WTT Event Run Sheets</h2>
                  <h3>
                    Operator{" "}
                    {JSON.parse(getFromLocalStorage("Operator"))[0].first_name}{" "}
                    {JSON.parse(getFromLocalStorage("Operator"))[0].last_name}
                  </h3>

                  <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                    {/* <Form.Label>Stall name</Form.Label> */}

                    <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                      <Form.Label>Select Stall</Form.Label>
                      <Form.Control
                        as="select"
                        size="sm"
                        custom
                        value={selectedVendorId}
                        onChange={(e) => {
                          this.setState(
                            { selectedVendorId: "" + e.target.value },
                            () => {
                              this.getDashboardData();
                            }
                          );
                          saveToLocalStorage(
                            "selectedVendorId",
                            "" + e.target.value
                          );
                        }}
                      >
                        {vendor_ids && vendor_ids.length == 1 ? (
                          <option value={vendor_ids[0].user_ptr_id}>
                            {vendor_ids[0].title}
                          </option>
                        ) : (
                          <>
                            <option value={0}>All</option>
                            {vendor_ids &&
                              vendor_ids.map((e) => {
                                return (
                                  <option value={"" + e.user_ptr_id}>
                                    {e.title}
                                  </option>
                                );
                              })}
                          </>
                        )}
                      </Form.Control>
                    </Form.Group>
                  </Form.Group>
                </Col>
              </Row>
              <Col xs={6} md={6} lg={6}>
                <b>Requested Events in Date Range:</b>
                <Row style={{ marginTop: 10 }}>
                  <Col xs={4} md={4} lg={4}>
                    <p>Start Date</p>
                    <DatePicker
                      dateFormat={"dd/MM/yyyy"}
                      selected={startDate}
                      onChange={(date) => this.setStartDate(date)}
                    />
                  </Col>
                  <Col xs={4} md={4} lg={4}>
                    <p>End Date</p>
                    <DatePicker
                      dateFormat={"dd/MM/yyyy"}
                      selected={endDate}
                      onChange={(date) => this.setEndDate(date)}
                    />
                  </Col>
                  <Col xs={4} md={4} lg={4}>
                    <p>&nbsp;</p>
                    <Button
                      onClick={() => {
                        this.setState({ resetFilter: "false" }, () => {
                          saveToLocalStorage(
                            "resetFilter",
                            this.state.resetFilter.toString()
                          );
                          this.getDashboardData(this.state.requestState);
                        });
                      }}
                    >
                      Apply
                    </Button>
                  </Col>
                </Row>
              </Col>

              <Row className="table-header-row">
                <Col xs={12} md={2} lg={2} className="table-header-col">
                  Event Date
                </Col>
                <Col xs={12} md={3} lg={3} className="table-header-col">
                  Stall Name
                </Col>
                <Col xs={12} md={2} lg={2} className="table-header-col">
                  Event Location
                </Col>
                <Col xs={12} md={2} lg={2} className="table-header-col">
                  Event name
                </Col>
                <Col xs={12} md={2} lg={2} className="table-header-col">
                  Bump in Date
                </Col>
              </Row>
              {bookingRequests.map((item, requestIndex) => {
                let cuisine_array = [...new Set(item.cuisine_array)];
                return (
                  <Accordion key={item.id}>
                    <>
                      <Accordion.Toggle as={Card.Header} eventKey={item.id}>
                        <Row className="table-row">
                          <Col xs={12} md={1} lg={1} className="table-col">
                            <span>
                              <b className="mobile-only">Booking request #:</b>{" "}
                              {item.event_date}
                            </span>
                          </Col>

                          <Col xs={12} md={2} lg={2} className="table-col">
                            <span>
                              <b className="mobile-only">Organisation Email</b>
                              {item.vendor__title} -- {item.vendor__rego_number}
                            </span>
                          </Col>
                          <Col xs={12} md={3} lg={3} className="table-col">
                            <span>
                              <b className="mobile-only">Phone Number</b>
                              {item.location}
                            </span>
                          </Col>
                          <Col xs={12} md={2} lg={2} className="table-col">
                            <span>
                              <b className="mobile-only">Organisation Name:</b>
                              {item.event_name}
                            </span>
                          </Col>
                          <Col xs={12} md={2} lg={2} className="table-col">
                            <span>
                              <b className="mobile-only">Organisation Name:</b>
                              {item.truck_manager}
                            </span>
                          </Col>

                          <Col
                            xs={12}
                            md={1}
                            lg={1}
                            className="table-col"
                            style={{ textAlign: "center" }}
                          >
                            <span>
                              <b className="mobile-only">Truck Manager</b>
                              {item.bump_in_date}
                            </span>
                          </Col>
                          <Col
                            xs={12}
                            md={3}
                            lg={3}
                            className="table-col"
                            style={{ textAlign: "center" }}
                          >
                            <Row style={{ marginBottom: 10 }}></Row>
                          </Col>
                        </Row>
                        <Accordion.Collapse
                          eventKey={item.id}
                          style={{ padding: 20, backgroundColor: "#eff2f5" }}
                        >
                          <>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Bump In Details:
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.bump_in_date}: {item.bump_in_start_time} -{" "}
                                {item.bump_in_end_time}
                              </Col>
                            </Row>

                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Bump Out Details:
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.bump_out_date}: {item.bump_out_start_time}{" "}
                                - {item.bump_out_end_time}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Site Contact
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.site_contact} - {item.site_contact_number}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Power Arrengments:
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.power}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Additional Notes
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.other_notes}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Multiple Day Trading Options
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.multiple_day_trading_options}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Multiple Day Trading Options Time
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.multiple_day_trading_time_options}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Beverage Notes
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.beverage_notes}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                {item.first_additional_document_name}
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                <a
                                  href={
                                    AWS_URL +
                                    "/" +
                                    item.first_addition_document_option
                                  }
                                  target="_blank"
                                >
                                  {item.first_additional_document_name}{" "}
                                </a>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                {item.second_additional_document_name}
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                <a
                                  href={
                                    AWS_URL +
                                    "/" +
                                    item.second_addition_document_option
                                  }
                                  target="_blank"
                                >
                                  {item.second_additional_document_name}{" "}
                                </a>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                {item.third_additional_document_name}
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                <a
                                  href={
                                    AWS_URL +
                                    "/" +
                                    item.third_addition_document_option
                                  }
                                  target="_blank"
                                >
                                  {item.third_additional_document_name}{" "}
                                </a>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Voucher Notes
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.vouchers_notes}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Truck Manager:
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.truck_manager}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                <PDFDownloadLink
                                  document={<RunsheetPdfCreator item={item} />}
                                  fileName={
                                    "runsheet" +
                                    item.vendor__title +
                                    "-" +
                                    item.event_name +
                                    ".pdf"
                                  }
                                >
                                  {({ blob, url, loading, error }) =>
                                    loading
                                      ? "Loading document..."
                                      : "Download PDF!"
                                  }
                                </PDFDownloadLink>
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                <Button
                                  variant="primary"
                                  onClick={() => this.emailRunSheet(item)}
                                >
                                  Email Run Sheet
                                </Button>
                              </Col>
                            </Row>

                            <Row>
                              {item.vendor__logo != null ? (
                                <Col xs={2} md={2} lg={2}>
                                  <img
                                    style={{ width: "100%" }}
                                    src={AWS_URL + "/" + item.vendor__logo}
                                    alt="Vendor Logo"
                                  />
                                </Col>
                              ) : null}
                            </Row>

                            {item.payment_status == "complete" ? (
                              <>
                                <Row>
                                  <Col xs={6} md={6} lg={6}>
                                    Payment Source
                                  </Col>
                                  <Col xs={6} md={6} lg={6}>
                                    {item.payment_source}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={6} md={6} lg={6}>
                                    Payment Receipt Number
                                  </Col>
                                  <Col xs={6} md={6} lg={6}>
                                    {item.payment_receipt_number}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={6} md={6} lg={6}>
                                    Payment Date
                                  </Col>
                                  <Col xs={6} md={6} lg={6}>
                                    {item.payment_date}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={6} md={6} lg={6}>
                                    Payment Time
                                  </Col>
                                  <Col xs={6} md={6} lg={6}>
                                    {item.payment_time}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={6} md={6} lg={6}>
                                    Payment Total Amount
                                  </Col>
                                  <Col xs={6} md={6} lg={6}>
                                    $
                                    {Number(item.payment_total_amount).toFixed(
                                      2
                                    )}
                                  </Col>
                                </Row>
                              </>
                            ) : null}
                          </>
                        </Accordion.Collapse>
                      </Accordion.Toggle>
                    </>
                  </Accordion>
                );
              })}
              <Row style={{ marginTop: 10, marginBottom: 10 }}>
                <Col xs={2} md={2} lg={2}>
                  <p>
                    <b>Showing Page:</b>
                  </p>
                </Col>
                <Col xs={10} md={10} lg={10} style={{ textAlign: "center" }}>
                  <div style={{ width: "98%" }}>
                    <Pagination>
                      <Pagination.Prev
                        onClick={() => {
                          this.setState(
                            { pageSelected: this.state.pageSelected - 1 },
                            () => {
                              this.getDashboardData(this.state.requestState);
                            }
                          );
                        }}
                      />
                      <Pagination.Item active>{pageSelected}</Pagination.Item>
                      <Pagination.Next
                        onClick={() => {
                          this.setState(
                            { pageSelected: this.state.pageSelected + 1 },
                            () => {
                              this.getDashboardData(this.state.requestState);
                            }
                          );
                        }}
                      />
                    </Pagination>
                  </div>
                </Col>
              </Row>
              {this.renderInviteModal()}
              {this.renderBookingStatusChangeModal()}
              {this.renderCustomerResponseModal()}
              {this.renderCustomerEmailModal()}
            </Container>
            <Footer />
          </>
        ) : (
          <div style={{ textAlign: "center" }}>
            <img src={require("../../assets/img/loading.gif")}></img>
          </div>
        )}
      </div>
    );
  }

  renderInviteModal() {
    const {
      inviteVendorModal,
      selectedRequest,
      vendors,
      isLoadingSendingVendorRequest,
      nonCuisineVendors,
      isLoadingVendor,
      selectedCuisine,
    } = this.state;
    var vendorList = vendors;
    var nonCuisineVendorList = nonCuisineVendors;

    return (
      <>
        {selectedRequest && selectedRequest.id ? (
          <Modal
            show={inviteVendorModal}
            onHide={() => {
              this.setState({ inviteVendorModal: false });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Send Request to Vendors</Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{ textAlign: "center", width: "90%", margin: "auto" }}
            >
              {!isLoadingVendor ? (
                <>
                  <Accordion>
                    <Accordion.Toggle as={Card.Header} eventKey={1}>
                      <>
                        <p style={{ textTransform: "capitalize" }}>
                          <b>Cuisine: {selectedCuisine}</b>
                        </p>
                        <p>
                          Found {vendorList.length} vendors. Click here to view
                        </p>
                        <Accordion.Collapse
                          eventKey={1}
                          style={{ padding: 20, backgroundColor: "#eff2f5" }}
                        >
                          <>
                            <div>
                              {vendorList.map((vendor, index) => {
                                return (
                                  <Row
                                    style={{
                                      textAlign: "left",
                                      backgroundColor: "#ededed",
                                      marginBottom: 10,
                                    }}
                                  >
                                    <Col xs={6} md={6} lg={6}>
                                      <p>
                                        {vendor.title}{" "}
                                        <span>
                                          <b>
                                            {"(" +
                                              vendor.catering_packs +
                                              " catering packs in the system)"}
                                          </b>
                                        </span>{" "}
                                      </p>
                                      <p>{vendor.email}</p>
                                      <p>{vendor.address}</p>
                                      <p>{vendor.phone}</p>
                                    </Col>
                                    <Col
                                      xs={6}
                                      md={6}
                                      lg={6}
                                      style={{ textAlign: "right" }}
                                    >
                                      {isLoadingSendingVendorRequest ? (
                                        <Spinner
                                          animation="border"
                                          role="status"
                                        >
                                          <span className="sr-only">
                                            Loading...
                                          </span>
                                        </Spinner>
                                      ) : vendor.email_sent ||
                                        this.state.requestSentIds.find(
                                          (element) =>
                                            element == vendor.user_ptr_id
                                        ) ? (
                                        <p>
                                          Sent on{" "}
                                          {vendor.email_sent_on ||
                                            moment(new Date()).format(
                                              "DD MMM YYYY hh:mm A"
                                            )}
                                        </p>
                                      ) : (
                                        <Button
                                          style={{
                                            width: "100%",
                                            marginBottom: 20,
                                            marginTop: 10,
                                            backgroundColor: "#ef3f6d",
                                          }}
                                          className="button-enabled-pink-style"
                                          onClick={() => {
                                            this.sendRequestToVendor(
                                              selectedRequest.id,
                                              vendor.user_ptr_id
                                            );
                                          }}
                                        >
                                          Send
                                        </Button>
                                      )}
                                    </Col>
                                  </Row>
                                );
                              })}
                            </div>
                          </>
                        </Accordion.Collapse>
                      </>
                    </Accordion.Toggle>

                    <Accordion.Toggle as={Card.Header} eventKey={2}>
                      <>
                        <p>
                          <b>Cuisine: Others</b>
                        </p>
                        <p>
                          Found {nonCuisineVendorList.length} vendors. Click
                          here to view
                        </p>
                        <Accordion.Collapse
                          eventKey={2}
                          style={{ padding: 20, backgroundColor: "#eff2f5" }}
                        >
                          <>
                            <div>
                              {nonCuisineVendorList.map((vendor, index) => {
                                return (
                                  <Row
                                    style={{
                                      textAlign: "left",
                                      backgroundColor: "#ededed",
                                      marginBottom: 10,
                                    }}
                                  >
                                    <Col xs={6} md={6} lg={6}>
                                      <p>
                                        {vendor.title}{" "}
                                        <span>
                                          <b>
                                            {"(" +
                                              vendor.catering_packs +
                                              " catering packs in the system)"}
                                          </b>
                                        </span>{" "}
                                      </p>
                                      <p>{vendor.email}</p>
                                      <p>{vendor.address}</p>
                                      <p>{vendor.phone}</p>
                                    </Col>
                                    <Col
                                      xs={6}
                                      md={6}
                                      lg={6}
                                      style={{ textAlign: "right" }}
                                    >
                                      {isLoadingSendingVendorRequest ? (
                                        <Spinner
                                          animation="border"
                                          role="status"
                                        >
                                          <span className="sr-only">
                                            Loading...
                                          </span>
                                        </Spinner>
                                      ) : vendor.email_sent ||
                                        this.state.requestSentIds.find(
                                          (element) =>
                                            element == vendor.user_ptr_id
                                        ) ? (
                                        <p>
                                          Sent on{" "}
                                          {vendor.email_sent_on ||
                                            moment(new Date()).format(
                                              "DD MMM YYYY hh:mm A"
                                            )}
                                        </p>
                                      ) : (
                                        <Button
                                          style={{
                                            width: "100%",
                                            marginBottom: 20,
                                            marginTop: 10,
                                            backgroundColor: "#ef3f6d",
                                          }}
                                          className="button-enabled-pink-style"
                                          onClick={() => {
                                            this.sendRequestToVendor(
                                              selectedRequest.id,
                                              vendor.user_ptr_id
                                            );
                                          }}
                                        >
                                          Send
                                        </Button>
                                      )}
                                    </Col>
                                  </Row>
                                );
                              })}
                            </div>
                          </>
                        </Accordion.Collapse>
                      </>
                    </Accordion.Toggle>
                  </Accordion>
                </>
              ) : (
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              )}
            </Modal.Body>
          </Modal>
        ) : null}
      </>
    );
  }

  renderCustomerEmailModal() {
    const { isOpenCustomerEmailDetails, selectedBooking } = this.state;
    return (
      <>
        {selectedBooking && selectedBooking != null ? (
          <Modal
            show={isOpenCustomerEmailDetails}
            onHide={() => {
              this.setState({ isOpenCustomerEmailDetails: false });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Customer Email Details</Modal.Title>
            </Modal.Header>
            {/* <Modal.Body style={{ textAlign: 'center', width: '90%', margin: 'auto' }}>
                            {
                                selectedBooking.map((email) => {
                                    return (
                                        <div style={{ textAlign: 'left', backgroundColor: '#f7f7f7' }}>
                                            <p>Vendor: {email.vendor}</p>
                                            <p>Catering Pack Name: {email.catering_pack_name}</p>
                                            <p>Catering Pack Price per person: ${email.catering_pack_price}</p>

                                        </div>
                                    )
                                })}
                        </Modal.Body> */}
            <Modal.Footer></Modal.Footer>
          </Modal>
        ) : null}
      </>
    );
  }

  renderBookingStatusChangeModal() {
    const {
      isOpenBookingStatusChangeModal,
      selectedBooking,
      selectedBookingNewStatus,
      isLoadingChangeBookingStatus,
    } = this.state;

    return (
      <>
        {selectedBooking != null && selectedBookingNewStatus != "" ? (
          <Modal
            show={isOpenBookingStatusChangeModal}
            onHide={() => {
              this.setState({ isOpenBookingStatusChangeModal: false });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Change Booking Request #{selectedBooking.id} to{" "}
                {selectedBookingNewStatus}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{ textAlign: "center", width: "90%", margin: "auto" }}
            >
              <p>
                Are you sure you want to change Event Organiser Application
                Status For #{selectedBooking.organisation_name} to{" "}
                {selectedBookingNewStatus}?
              </p>
            </Modal.Body>

            <Modal.Footer>
              {isLoadingChangeBookingStatus ? (
                <div style={{ textAlign: "center" }}>
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <>
                  <Button
                    onClick={() => {
                      this.onChangeBookingStatusOnServer(
                        selectedBooking.id,
                        selectedBookingNewStatus
                      );
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    onClick={() => {
                      this.setState({ isOpenBookingStatusChangeModal: false });
                    }}
                  >
                    No
                  </Button>
                </>
              )}
            </Modal.Footer>
          </Modal>
        ) : null}
      </>
    );
  }

  renderCustomerResponseModal() {
    const {
      isOpenCustomerResponseModal,
      selectedBooking,
      isLoadingCustomerResponse,
      vendors,
      vendorCateringPacks,
      selectedCateringPack,
      reviewEmail,
    } = this.state;
    let min_fee = 0;
    let sub_total = 0;
    let min_call_out_fee = 0;
    if (selectedCateringPack && selectedBooking) {
      min_fee =
        Number(selectedCateringPack.package_price) *
        Number(selectedCateringPack.package_people);
      sub_total =
        Number(selectedCateringPack.package_price) *
        Number(selectedBooking.number_of_guests);
    }
    if (min_fee - sub_total > 0) {
      min_call_out_fee = min_fee - sub_total;
    }

    return (
      <>
        {selectedBooking != null ? (
          <Modal
            show={isOpenCustomerResponseModal}
            onHide={() => {
              this.setState({ isOpenCustomerResponseModal: false });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {!reviewEmail
                  ? "Sending Message to Customer"
                  : "Review Email and Send"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{ textAlign: "center", width: "90%", margin: "auto" }}
            >
              {!reviewEmail ? (
                <>
                  <p>
                    You are about to send a response to the customer regarding
                    his booking request. Please confirm and fill in the details
                    below to send the response.
                  </p>
                  <div style={{ textAlign: "left" }}>
                    <Row>
                      <Col xs={6} md={6} lg={6}>
                        Customer Name
                      </Col>
                      <Col xs={6} md={6} lg={6}>
                        {selectedBooking.name}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} md={6} lg={6}>
                        Customer Phone
                      </Col>
                      <Col xs={6} md={6} lg={6}>
                        {selectedBooking.phone}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} md={6} lg={6}>
                        Customer Email
                      </Col>
                      <Col xs={6} md={6} lg={6}>
                        {selectedBooking.email}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} md={6} lg={6}>
                        Booking Request Id
                      </Col>
                      <Col xs={6} md={6} lg={6}>
                        {selectedBooking.id}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} md={6} lg={6}>
                        Cuisine
                      </Col>
                      <Col xs={6} md={6} lg={6}>
                        {selectedBooking.cuisine}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} md={6} lg={6}>
                        Number of guests
                      </Col>
                      <Col xs={6} md={6} lg={6}>
                        {selectedBooking.number_of_guests}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} md={6} lg={6}>
                        Select the {selectedBooking.cuisine} vendor
                      </Col>
                      <Col xs={6} md={6} lg={6}>
                        <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                          <Form.Control
                            as="select"
                            size="sm"
                            custom
                            onChange={(e) => {
                              this.getVendorCateringPacks(
                                e.target.value,
                                selectedBooking
                              );
                            }}
                          >
                            <option key={0} value={-1}>
                              {"Select a vendor"}
                            </option>
                            {vendors.map((vendor, index) => {
                              return (
                                <option key={index} value={vendor.user_ptr_id}>
                                  {vendor.title}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    {vendorCateringPacks.length > 0 ? (
                      <Row>
                        <Col xs={6} md={6} lg={6}>
                          Select the catering pack
                        </Col>
                        <Col xs={6} md={6} lg={6}>
                          <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                            <Form.Control
                              as="select"
                              size="sm"
                              custom
                              onChange={(e) => {
                                this.setState({
                                  selectedCateringPack:
                                    vendorCateringPacks[e.target.value],
                                });
                              }}
                            >
                              <option key={0} value={-1}>
                                {"Select a catering pack"}
                              </option>
                              {vendorCateringPacks.map((pack, index) => {
                                return (
                                  <option
                                    style={{ whiteSpace: "pre-line" }}
                                    key={index}
                                    value={index}
                                  >
                                    {pack.package_name +
                                      "\n$" +
                                      pack.package_price +
                                      "/person" +
                                      "(For min " +
                                      pack.package_people +
                                      " people )"}
                                  </option>
                                );
                              })}
                            </Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        <Col xs={12} md={12} lg={12}>
                          The selected vendor hasn't uploaded any catering packs
                        </Col>
                      </Row>
                    )}

                    {!this.isEmpty(selectedCateringPack) ? (
                      <>
                        <Row>
                          <Col xs={6} md={6} lg={6}>
                            Catering Pack Name
                          </Col>
                          <Col xs={6} md={6} lg={6}>
                            {selectedCateringPack.package_name}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6} md={6} lg={6}>
                            Catering Pack Description
                          </Col>
                          <Col xs={6} md={6} lg={6}>
                            {selectedCateringPack.package_description}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6} md={6} lg={6}>
                            Price per person
                          </Col>
                          <Col xs={6} md={6} lg={6}>
                            {"$" + selectedCateringPack.package_price}
                          </Col>
                        </Row>
                      </>
                    ) : null}
                  </div>
                </>
              ) : (
                <div style={{ textAlign: "left" }}>
                  <p>Dear {selectedBooking.name}</p>
                  <p>
                    Thank you for your request. We have contacted some of the
                    best food trucks and based on the your requirements we found
                    the best quote for you. Following are the details -{" "}
                  </p>
                  <Row>
                    <Col xs={6} md={6} lg={6}>
                      Catering Pack Name
                    </Col>
                    <Col xs={6} md={6} lg={6}>
                      {selectedCateringPack.package_name}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} md={6} lg={6}>
                      Catering Pack Description
                    </Col>
                    <Col xs={6} md={6} lg={6}>
                      {selectedCateringPack.package_description}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} md={6} lg={6}>
                      Number of Guests
                    </Col>
                    <Col xs={6} md={6} lg={6}>
                      {Number(selectedBooking.number_of_guests)}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} md={6} lg={6}>
                      Sub Total
                    </Col>
                    <Col xs={6} md={6} lg={6}>
                      {"$" + Number(sub_total).toFixed(2)}
                    </Col>
                  </Row>
                  {Number(min_fee) - Number(sub_total) > 0 ? (
                    <OverlayTrigger
                      placement="top-start"
                      overlay={
                        <Tooltip id="button-tooltip-2">
                          This pack is for min{" "}
                          {selectedCateringPack.package_people} people so a min
                          callout fee will be applied to your order
                        </Tooltip>
                      }
                    >
                      <Row>
                        <Col xs={6} md={6} lg={6}>
                          Min Call Out Fee:
                        </Col>
                        <Col xs={6} md={6} lg={6}>
                          {"$" + Number(min_call_out_fee).toFixed(2)}
                        </Col>
                      </Row>
                    </OverlayTrigger>
                  ) : null}
                  <Row>
                    <Col xs={6} md={6} lg={6}>
                      Total:
                    </Col>
                    <Col xs={6} md={6} lg={6}>
                      {"$" + Number(min_call_out_fee + sub_total).toFixed(2)}
                    </Col>
                  </Row>
                  <p>We are holding this booking for the next 7 days.</p>
                  <p>
                    If you have any questions please do not hesitate to email me
                    back or call me on 0411738771
                  </p>
                  <p>Regards</p>
                  <p>Alana Tarev</p>
                  <p>Customer Support</p>
                  <p>WTT Team</p>
                </div>
              )}
            </Modal.Body>

            <Modal.Footer>
              {isLoadingCustomerResponse ? (
                <div style={{ textAlign: "center" }}>
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <>
                  {!reviewEmail ? (
                    <Button
                      style={{
                        width: "100%",
                        marginBottom: 20,
                        marginTop: 10,
                        backgroundColor: "#ef3f6d",
                      }}
                      className="button-enabled-pink-style"
                      onClick={() => {
                        if (
                          !this.isEmpty(selectedCateringPack) &&
                          vendorCateringPacks.length > 0
                        ) {
                          this.setState({ reviewEmail: true });
                        } else {
                          alert(
                            "Please select a vendor and a catering pack to send the email to the customer!"
                          );
                        }
                      }}
                    >
                      Review and Send
                    </Button>
                  ) : (
                    <>
                      <Button
                        style={{
                          width: "100%",
                          marginBottom: 20,
                          marginTop: 10,
                          backgroundColor: "#ef3f6d",
                        }}
                        className="button-enabled-pink-style"
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure? This will send an email to the customer with the above details"
                            )
                          ) {
                            this.sendEmailToCustomer(
                              selectedBooking,
                              selectedCateringPack
                            );
                          } else {
                          }
                        }}
                      >
                        Send
                      </Button>

                      <Button
                        style={{
                          width: "100%",
                          marginBottom: 20,
                          marginTop: 10,
                          backgroundColor: "#ef3f6d",
                        }}
                        className="button-enabled-pink-style"
                        onClick={() => {
                          this.setState({ reviewEmail: false });
                        }}
                      >
                        Back
                      </Button>
                    </>
                  )}
                </>
              )}
            </Modal.Footer>
          </Modal>
        ) : null}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(OperatorRunSheets, {
    fallback: <ErrorFallbackComponent />,
  })
);
